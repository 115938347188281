<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import axios from 'axios';
import Swal from "sweetalert2";
/**
 * Email-read component
 */
export default {
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Détails Personne ",
            items: [
                {
                    text: "Détails Personne ",
                    href: "/alert/list-alert"
                },
                {
                    text: "Détails",
                    active: true
                }
            ],
            person: {},
            coversrc: null,
            pertinence: null,
            status: null,
            alert: {},
            send: true,
            reqError: null,
            reqResponse: null,
            submitted: false,

        };
    },
    created() {
    },
    validations: {
    },
    methods: {
        ...reqMethods,
        deleteThisPerson() {
            Swal.fire({
                title: "Etes vous sûr ?",
                text: "voulez-Vous supprimer cette personne ? Cette action est irréversible!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "supprimer!"
            }).then(async result => {
                if (result.value) {
                    const formData = new FormData();
                    formData.append('id', parseInt(this.$route.params.id));
                    formData.append('is_active', false);
                    try {
                        const response = await axios.put('https://api.alcit.sims-technologie.com/api/v1/investigation/incident/', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': localStorage.getItem('token'),
                            },
                        });
                        Swal.fire(response.data.message, "Cette personne à été Supprimer!", "success");
                        this.$router.push('/liste-personne');
                    } catch (error) {
                        console.log(error);
                        Swal.fire({
                            title: "Oops...",
                            text: "Erreur lors de l'ajout de l'alerte",
                            icon: "error",
                            confirmButtonClass: "btn btn-primary w-xs mt-2",
                            buttonsStyling: false,
                            showCloseButton: true,
                        });
                    }
                }
            })
        },
        getPersonDetails() {
            this.person = JSON.parse(localStorage.getItem('person'));
            this.status = this.person?.status;
            console.log(this.status);
            console.log('infos personne');
            console.log(this.person);
        },
        async setStatus(status) {
            const formData = new FormData();
            formData.append('id', this.person?.id);
            formData.append('status', status);
            try {
                const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/personne/personne/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token'),
                    },
                });
                Swal.fire(response.data.message, "le statut à été changé!", "success");
                this.status = status;
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "Oops...",
                    text: "Erreur lors du changement de statut",
                    icon: "error",
                    confirmButtonClass: "btn btn-primary w-xs mt-2",
                    buttonsStyling: false,
                    showCloseButton: true,
                });
            }

        }
    },
    mounted() {
        this.getPersonDetails();
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <img src="@/assets/images/sound.png" id="imageId" class="d-none" alt="">
            <div class="col-12 mb-3">
                <div class="card">
                    <div class="row justify-content-between">
                        <div class="col-md-7">
                            <div class="btn-toolbar p-3" role="toolbar">
                                <button type="button" @click="deleteThisPerson()" class="btn btn-danger me-2">
                                    <i class="mdi mdi-trash-can"></i>
                                    Désactiver
                                </button>

                            </div>
                        </div>
                        <div class="col-md-5 d-flex justify-content-end">
                            <b-dropdown split text="Status" variant="warning" class="p-3">
                                <b-dropdown-item @click="setStatus('rechercher')">Recherché</b-dropdown-item>
                                <b-dropdown-item @click="setStatus('non_rechercher')">Non Recherché</b-dropdown-item>
                            </b-dropdown>
                            <strong v-if="status == 'rechercher'" class="text-danger p-3 mt-2">
                                Recherché
                            </strong>
                            <strong v-if="status == 'non_rechercher'" class="text-success p-3 mt-2">
                                Non Recherché
                            </strong>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="person?.photos?.length > 0">
                            <b-card no-body class="mb-1">
                                <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                                    <h6 class="m-0">
                                        <a class="text-dark" href="javascript: void(0);">
                                            <strong>Images </strong>
                                        </a>
                                    </h6>
                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="row" v-viewer>
                                            <div class="col-xl-2 col-6" v-for="(photo, index) in person.photos" :key=index>
                                                <div class="card">
                                                    <img class="card-img-top img-fluid"
                                                        :src="`https://api.alcit.sims-technologie.com${photo.photos}`"
                                                        alt="Card image cap" />
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Numero CNI:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.cni_numero }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Nom:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.nom }}</h5>
                            </div>
                        </div>

                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Prenom:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.prenom }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2"><strong class="font-size-13">Alias:</strong></div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.alias }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Genre:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info"> {{ person.sexe }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Date de naissance:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.date_naissance }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2"><strong class="font-size-13">Taille:</strong></div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.taille }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2"><strong class="font-size-13">Poids:</strong></div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.poids }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2"><strong class="font-size-13">Teint:</strong></div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.teint }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2"><strong class="font-size-13">Signe particulier:</strong></div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.signe_particulier }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2"><strong class="font-size-13">Nationalité:</strong></div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.nationalite }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Statut:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 v-if="status == 'rechercher'" class="font-size-13 text-danger">Rechercher</h5>
                                <h5 v-if="status == 'non_rechercher'" class="font-size-13 text-success">Non Rechercher</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Motif de recherche:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info"> {{ person.motif_recherche }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Niveau dangerosité:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 v-if="person.niveau_dangerosite == 'normal'"
                                    class="font-size-13 badge badge-pill badge-soft-info"> Normal </h5>
                                <h5 v-if="person.niveau_dangerosite == 'dangereux'"
                                    class="font-size-13 badge badge-pill badge-soft-danger"> Dangereux </h5>
                                <h5 v-if="person.niveau_dangerosite == 'tres_dangereux'"
                                    class="font-size-13 badge badge-pill badge-soft-danger"> Très Dangereux </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Conduite à tenir:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ person.conduite_a_tenir }} </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
